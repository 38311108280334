<template>
  <v-container fluid  v-if="view.public && !day.published">
    <h2 class="ma-12 text-center">
      <v-avatar color="red">
        <v-icon color="white">fas fa-eye-slash</v-icon>
      </v-avatar>
      <br class="hidden-md-and-up">
      This bracket has not been published yet.
    </h2>
 </v-container>
  <v-container fluid  v-else-if="unsure">
    <message-card class="pa-12" v-if="!division.hydrated">
      <v-progress-circular indeterminate color="color3"></v-progress-circular>
    </message-card>
    <h2 class="ma-12 text-center" v-else>
      <v-avatar color="red">
        <v-icon color="white">fas fa-eye-slash</v-icon>
      </v-avatar>
      <br class="hidden-md-and-up">
      The number of teams for this round of play can not be determined yet.
    </h2>
  </v-container>
  <v-container fluid v-else class="pa-0" :key="round.id">
    <v-tabs
      background-color="color3"
      slider-color="color3Text"
      active-class="color3Text color3--text"
      class="color3 color3Text--text"
      show-arrows
      center-active
    >
      <v-tab
        v-for="(tab, i) in tabs"
        :key="i"
        exact
        :to="tab.to"
        :class="tab.active ? 'color3--text' : 'color3Text--text'"
      >{{tab.text}}</v-tab>
    </v-tabs>
    <router-view
      :courting="courting"
      @match-click="onMatchClick"
    ></router-view>
    <v-btn
      v-if="courtFab"
      color="color3 color3Text--text"
      fab
      fixed
      bottom right
      :href="courtFab.url"
      target="_blank"
      :small="$vuetify.breakpoint.xs"
      style="margin-bottom: 60px"
    >
      <v-icon>{{courtFab.icon}}</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import TournamenMixin from '@/Mixins/TournamentMixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [TournamenMixin],
  props: ['courting'],
  data () {
    return {
      wizard: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'admin',
      'view',
      'tournament',
      'getDivision'
    ]),
    courtFab () {
      return null
      // return this.division.props.includes('video') ? this.tournament.fabs.find(f => f.type === 'bracket' && f.bracketId === this.bracket.id) : null
    },
    division () {
      return this.getDivision(this.divisionId)
    },
    round () {
      if (!this.division) return null
      return this.division.days.find(f => f.id === this.dayId)
    },
    bracket () {
      if (!this.round) return null
      return this.round.brackets[0]
    },
    complete () {
      return this.bracket && this.bracket.isComplete
    },
    tabs () {
      const tabs = []
      if (!this.bracket) return tabs
      const prefix = this.tournament.isLeague ? 'league-' : ''
      const r = this.$route.name
      tabs.push({
        text: 'Teams',
        to: { name: prefix + 'bracket-seeding' },
        get active () { return r === this.to.name }
      })
      const w = this.bracket.type.toLowerCase().startsWith('single') ? 'Bracket' : 'Winners'
      tabs.push({
        text: w,
        to: { name: prefix + 'bracket-home' },
        get active () { return r === this.to.name }
      })
      if (this.bracket.losers.length) {
        tabs.push({
          text: this.bracket.losersName,
          to: { name: prefix + 'bracket-contenders' },
          get active () { return r === this.to.name }
        })
      }
      return tabs
    },
    unsure () {
      return this.round.teamCount === null
    }
  },
  methods: {
    onMatchClick (match) {
      this.$emit('match-click', match)
    }
  }
}
</script>

<style>
</style>
